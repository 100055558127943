<template>
  <v-autocomplete v-bind="$attrs"
                  @change="changed"
                  color="primary darken-1"
                  item-color="primary darken-1"
                  dense
                  chips
                  small-chips
                  :label="label"
                  multiple
                  clearable
                  :hide-details="!hint"
                  :persistent-hint="hint !== null"
                  :hint="hint"
                  :loading="dataPending"
                  :open-on-clear="false"
                  outlined
                  :style="getWidth">
    <!-- Custom selection -->
    <template #selection="{ parent, item, index }">
      <!-- Custom chip -->
      <v-chip v-if="index < 3"
              :color="parent.itemColor"
              :small="parent.smallChips">
        <span class="ellipsis-chip-label">{{ item.text }}</span>
        <v-icon v-if="parent.deletableChips"
                small
                right
                @click="parent.selectItem(item)">cancel</v-icon>
      </v-chip>

      <!-- Appending text -->
      <span v-if="index === 3"
            class="grey--text text-caption mr-1">(+{{ $attrs.value.length - 3 }} others)</span>
    </template>
  </v-autocomplete>
</template>

<script>
import { inspectionControlMixin } from '../utils/mixins/inspectionControl.mixin'

export default {
  name: 'pibot-mapbox-inspection-select',
  mixins: [inspectionControlMixin],
  props: {
    label: {
      type: String,
      default: 'Inspections'
    },
    hint: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getWidth () {
      if (this.small) return 'width: 150px'
      else return ''
    }
  },
  methods: {
    changed (newValue) {
      this.$emit('change', newValue)
    },
    toggle () {
      this.$emit('toggle')
    }
  }
}
</script>
